import {FC} from "react";
import {useTranslation} from "react-i18next";

import {useTitle} from "@app/common/layouts/hooks/useTitle";

export const NotFoundPage: FC = () => {
    const {t} = useTranslation();
    useTitle(NotFoundPageTitle)

    return <div>
        <h1>{t(NotFoundPageTitle)}</h1>
        <p>{t('NotFound.description')}</p>
    </div>
}

export const NotFoundPageTitle = 'NotFound.title';
