// Object.fromEntries polyfill
import "polyfill-object.fromentries";
import { createRoot } from "react-dom/client";

import {App} from "./App/App";

const rootAppElement = document.getElementById('app-root');

if (rootAppElement) {
    const root = createRoot(rootAppElement);
    root.render(<App />);
}
