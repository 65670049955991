import {DetailedHTMLProps, FC, forwardRef, InputHTMLAttributes} from "react";

import './input.scss';

export type InputProps = DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;

export const Input: FC<InputProps> = forwardRef((inputProps, ref) => {
    return <input {...inputProps} ref={ref} />
})

Input.displayName = 'Input';
