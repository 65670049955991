import {toast, ToastOptions} from "react-toastify";

import {Toast} from "@app/common/components/Toast/Toast";
import {ToastTypeEnum} from "@app/common/components/Toast/ToastTypeEnum";

export type ToastOpenerFn = (text: string, options?: ToastOptions, asHtml?: boolean, errorIdentifier?: string) => void;
export type ToastOpener = { [key in ToastTypeEnum]: ToastOpenerFn };

export const appToast: ToastOpener = {
    success: (text, options) => {
        toast.success(
            <Toast>{text}</Toast>, {containerId: 'app', icon: false, ...options}
        );
    },
    warning: (text, options) => {
        toast.warning(
            <Toast>{text}</Toast>, {containerId: 'app', icon: false, ...options}
        );
    },
    error: (text, options, asHtml, errorIdentifier) => {
        toast.error(
            <Toast errorIdentifier={errorIdentifier}>
                {asHtml ? <span dangerouslySetInnerHTML={{__html: text}}/> : text}
            </Toast>,
            {containerId: 'app', icon: false, ...options}
        );
    },
    info: (text, options) => {
        toast.info(
            <Toast>{text}</Toast>, {containerId: 'app', icon: false, ...options}
        );
    },
}

export const modalToast: ToastOpener = {
    success: (text, options) => {
        toast.success(
            <Toast>{text}</Toast>, {containerId: 'modal', icon: false, ...options});
    },
    warning: (text, options) => {
        toast.warning(
            <Toast>{text}</Toast>, {containerId: 'modal', icon: false, ...options});
    },
    error: (text, options, asHtml, errorIdentifier) => {
        toast.error(
            <Toast errorIdentifier={errorIdentifier}>
                {asHtml ? <span dangerouslySetInnerHTML={{__html: text}}/> : text}
            </Toast>,
            {containerId: 'modal', icon: false, ...options}
        );
    },
    info: (text, options) => {
        toast.info(
            <Toast>{text}</Toast>, {containerId: 'modal', icon: false, ...options});
    },
}

export const dismissToasts = () => {
    toast.dismiss();
}
