import {FCWithChildren} from "@app/common/model/FCWithChildren";

import './field.scss';

export type FieldProps = {
    id?: string;
    label?: string;
    error?: string;
}

export const Field: FCWithChildren<FieldProps> = ({children, id, label, error}) => {
    return <div className="field">
        {children}
        {label && <label htmlFor={id} className="field__label">{label}</label>}
        {error && <div className="field__error">{error}</div>}
    </div>
}
