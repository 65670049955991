import {useEffect} from "react";

import {useAppConfig} from "@app/common/hooks/useAppConfig";

export const useTitle = (title: string) => {
    const {appName} = useAppConfig();

    useEffect(() => {
        document.title = `${title} – ${appName}`;
    }, [title]);
}
