import {formatRFC3339} from "date-fns/formatRFC3339";
import {useCallback} from "react";
import {useTranslation} from "react-i18next";

import {useAppConfig} from "@app/common/hooks/useAppConfig";
import {ApiEndpoint} from "@app/common/model/ApiEndpoint";
import {ApiError} from "@app/common/model/ApiError";
import {isErrorResponse} from "@app/common/model/ErrorResponse";
import {QueryParam} from "@app/common/model/QueryParam";

export const useApi = <ResponseType, PayloadType = undefined, RouteParamNames extends string = string>(
    endpoint: ApiEndpoint<RouteParamNames>,
    queryParams?: QueryParam[],
    customHeaders?: HeadersInit,
) => {
    const {i18n} = useTranslation();
    const {apiUrl} = useAppConfig();

    return useCallback(async (payload?: PayloadType, abortController?: AbortController): Promise<ResponseType>  => {
        const headers = new Headers(customHeaders || {
            'Accept-Language': i18n.language,
            'Content-Type': 'application/json',
        });

        const fetchInit: RequestInit = {
            method: endpoint.method,
            headers,
            body: payload ? JSON.stringify(payload, (_key: string, value: unknown): unknown => {
                if (value instanceof Date) {
                    return formatRFC3339(value);
                }
                return value;
            }) : null,
            signal: abortController ? abortController.signal : undefined,
            credentials: 'include',
        };

        const url = new URL(`${apiUrl}${endpoint.url}`);
        if (queryParams) {
            queryParams.forEach((queryParam) => {
                url.searchParams.append(queryParam.name, queryParam.value);
            });
        }

        const response = await fetch(url.toString(), fetchInit);
        if (response.ok) {
            return await response.json() as ResponseType;
        } else {
            const errorResponse = await response.json();
            if (isErrorResponse(errorResponse)) {
                throw new ApiError(response.status, errorResponse);
            }

            throw new Error(`${response.status} - ${errorResponse}`);
        }
    }, [apiUrl, customHeaders, endpoint.method, endpoint.url, i18n.language, queryParams]);
}
