import {FC} from "react";

import {Button, ButtonVariant} from "@app/common/components/form/Button/Button";
import {Field} from "@app/common/components/form/Field/Field";

export type SubmitProps = {
    label: string;
    variant?: ButtonVariant;
}

export const Submit: FC<SubmitProps> = ({label, variant}) => {
    return <Field>
        <Button type="submit" variant={variant}>
            {label}
        </Button>
    </Field>
}
