import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";

import {Loading} from "@app/common/components/Loading/Loading";
import {ToastContainer} from "@app/common/components/Toast/ToastContainer/ToastContainer";
import {LanguageSelect} from "@app/common/layouts/components/LanguageSelect/LanguageSelect";
import {Logo} from "@app/common/layouts/components/Logo/Logo";
import {useUserInfo} from "@app/common/layouts/SecuredLayout/hooks/useUserInfo";
import {FCWithChildren} from "@app/common/model/FCWithChildren";
import {DashboardPagePath} from "@app/Dashboard/DashboardPage";

import './anonymousLayout.scss';

export const AnonymousLayout: FCWithChildren = ({children}) => {
    const navigate = useNavigate();

    const [userVerified, setUserVerified] = useState<boolean>(false);
    const userInfo = useUserInfo();

    useEffect(() => {
        if (userVerified) {
            return;
        }

        userInfo()
            .then((user) => {
                navigate(DashboardPagePath, {
                    state: user,
                });
            })
            .catch(() => {
                // ignored
            })
            .finally(() => {
                setUserVerified(true);
            });
    }, [navigate, userInfo, userVerified])

    if (!userVerified) {
        return <Loading />
    }

    return <div className="anonymous-layout">
        <ToastContainer/>
        <Logo/>
        <LanguageSelect/>
        <div className="anonymous-layout__container">
            {children}
        </div>
    </div>
}
