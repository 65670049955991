import {ToastContentProps, TypeOptions} from "react-toastify";

import {Alert} from "@app/common/components/Alert/Alert";
import {FCWithChildren} from "@app/common/model/FCWithChildren";

export type ToastProps = Partial<ToastContentProps> & {
    errorIdentifier?: string;
}

export const Toast: FCWithChildren<ToastProps> = ({children, closeToast, toastProps}) => {
    const toastType: TypeOptions = toastProps ? (toastProps.type ? toastProps.type : 'info') : 'info';

    return <Alert
        type={toastType}
        className="toast"
        close={closeToast}
    >
        {children}
    </Alert>
}
