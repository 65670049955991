import {i18n as I18next} from "i18next";
import {Suspense, useEffect, useState} from "react";
import {I18nextProvider} from "react-i18next";

import {i18nFactory} from "@app/App/I18Provider/i18nFactory";
import {useAppConfig} from "@app/common/hooks/useAppConfig";
import {FCWithChildren} from "@app/common/model/FCWithChildren";

export const I18Provider: FCWithChildren = ({children}) => {
    const config = useAppConfig();

    const [i18n, setI18n] = useState<I18next|undefined>(undefined);
    useEffect(() => {
        setI18n(
            i18nFactory(config),
        );
    }, [config]);

    if (!i18n) {
        return null;
    }

    return <I18nextProvider i18n={i18n}>
        <Suspense fallback={<div />}>{children}</Suspense>
    </I18nextProvider>
}
