import {FC, useCallback} from "react";
import {SubmitHandler, useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";

import {Field} from "@app/common/components/form/Field/Field";
import {Input} from "@app/common/components/form/Input/Input";
import {Submit} from "@app/common/components/form/Submit/Submit";
import {useShowErrorToast} from "@app/common/hooks/useShowErrorToast";
import {DashboardPagePath} from "@app/Dashboard/DashboardPage";
import {LoginRequest} from "@app/Login/LoginForm/LoginRequest";
import {useLogIn} from "@app/Login/LoginForm/useLogIn";

export const LoginForm: FC = () => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const showErrorToast = useShowErrorToast();

    const logIn = useLogIn();

    const {formState, handleSubmit, register} = useForm<LoginRequest>({
        defaultValues: {email: '', password: ''},
    });

    const onSubmit = useCallback<SubmitHandler<LoginRequest>>(async (data) => {
        try {
            const user = await logIn(data);
            navigate(DashboardPagePath, {
                state: user,
            });
        } catch (error: unknown) {
            showErrorToast(error as Error);
        }
    }, [logIn, navigate, showErrorToast])

    return <form onSubmit={handleSubmit(onSubmit)}>
        <Field id="" label={t('Login.form.email.label')} error={formState.errors.email?.message}>
            <Input id="email" {...register('email', {
                required: t('Login.form.email.required'),
            })} />
        </Field>
        <Field id="password" label={t('Login.form.password.label')} error={formState.errors.password?.message}>
            <Input id="password" type="password" {...register('password', {
                required: t('Login.form.password.required'),
            })} />
        </Field>
        <Submit label={t('Login.form.logIn')} />
    </form>

}
