import {useTranslation} from "react-i18next";

import {useApi} from "@app/common/hooks/useApi";
import {HttpMethod} from "@app/common/model/HttpMethod";
import {User} from "@app/common/model/User";
import {LoginRequest} from "@app/Login/LoginForm/LoginRequest";

export const useLogIn = () => {
    const {i18n} = useTranslation();

    return useApi<User, LoginRequest>({url: '/login', method: HttpMethod.POST}, [
        {name: '_locale', value: i18n.language}
    ]);
}
