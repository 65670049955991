import classNames from "classnames";
import {FC} from "react";

import './hamburgerIcon.scss';

export type HamburgerIconProps = {
    opened: boolean;
    onClick: () => void;
}

export const HamburgerIcon: FC<HamburgerIconProps> = ({opened, onClick}) => {
    return <div className={classNames('hamburger-icon', opened && 'hamburger-icon--opened')} onClick={onClick}>
        <div className="hamburger-icon__bar hamburger-icon__bar--1"/>
        <div className="hamburger-icon__bar hamburger-icon__bar--2"/>
        <div className="hamburger-icon__bar hamburger-icon__bar--3"/>
    </div>

}
