import I18nextBrowserLanguageDetector from "i18next-browser-languagedetector";

import {Config} from "@app/config";

export const languageDetectorFactory = (config: Config) => {
    const languageDetector = new I18nextBrowserLanguageDetector();

    languageDetector.addDetector({
        name: 'defaultLanguage',
        lookup: () => config.defaultLng,
    });

    languageDetector.addDetector({
        name: 'normalizedNavigator',
        lookup: () => {
            const navigatorDetected = languageDetector.detectors.navigator.lookup() as string[] | undefined;
            if (!navigatorDetected) {
                return navigatorDetected;
            }

            const shortLanguageCodeMap = new Map<string, string>();
            shortLanguageCodeMap.set('en', 'en-GB');
            shortLanguageCodeMap.set('cs', 'cs-CZ');
            shortLanguageCodeMap.set('hu', 'hu-HU');
            shortLanguageCodeMap.set('ro', 'ro-RO');
            shortLanguageCodeMap.set('sk', 'sk-SK');
            shortLanguageCodeMap.set('vi', 'vi-VN');
            // E2E tests language detection workaround
            shortLanguageCodeMap.set('en-US', 'en-GB');

            return navigatorDetected.map((language) => {
                return shortLanguageCodeMap.get(language) || language;
            })
        }
    });

    return languageDetector;
}
