import {useCallback} from "react";

import {appToast} from "@app/common/components/Toast/toastOpener";
import {ApiError} from "@app/common/model/ApiError";

export const useShowErrorToast = () => {
    return useCallback((error: Error) => {
        if (error instanceof ApiError) {
            if (error.response.errors.length > 0) {
                error.response.errors.forEach((errorText) => {
                    appToast.error(errorText);
                });
            } else {
                appToast.error(error.response.message);
            }
        } else {
            appToast.error(error.message);
        }
    }, []);
}
