import classNames from "classnames";
import {DetailedHTMLProps, ButtonHTMLAttributes, forwardRef} from "react";

import {FCWithChildren} from "@app/common/model/FCWithChildren";

import './button.scss';

export enum ButtonVariant {
    primary = 'primary',
    secondary = 'secondary',
}

export type ButtonProps = DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> & {
    variant?: ButtonVariant;
};

export const Button: FCWithChildren<ButtonProps> = forwardRef(({children, variant = ButtonVariant.primary, ...buttonProps}, ref) => {
    return <button {...buttonProps} className={classNames('button', `button--${variant}`)} ref={ref}>
        {children}
    </button>
});

Button.displayName = 'Button';
