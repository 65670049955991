import {FC} from "react";
import {useTranslation} from "react-i18next";

import {useTitle} from "@app/common/layouts/hooks/useTitle";

export const UserSettingsPage: FC = () => {
    const {t} = useTranslation();
    useTitle(t(UserSettingsPageTitle));

    return <div>
        <h1>{t(UserSettingsPageTitle)}</h1>
        <p>TODO</p>
    </div>
}

export const UserSettingsPageTitle = 'UserSettings.title';

export const UserSettingsPagePath = '/user-settings';
