// Intl polyfills
import '@formatjs/intl-locale/polyfill';
import '@formatjs/intl-getcanonicallocales/polyfill';
import '@formatjs/intl-pluralrules/polyfill';
import '@formatjs/intl-pluralrules/locale-data/cs';
import '@formatjs/intl-pluralrules/locale-data/en';
import '@formatjs/intl-pluralrules/locale-data/hu';
import '@formatjs/intl-pluralrules/locale-data/ro';
import '@formatjs/intl-pluralrules/locale-data/sk';
import '@formatjs/intl-pluralrules/locale-data/vi';
import '@formatjs/intl-numberformat/polyfill';
import '@formatjs/intl-numberformat/locale-data/cs';
import '@formatjs/intl-numberformat/locale-data/en';
import '@formatjs/intl-numberformat/locale-data/hu';
import '@formatjs/intl-numberformat/locale-data/ro';
import '@formatjs/intl-numberformat/locale-data/sk';
import '@formatjs/intl-numberformat/locale-data/vi';

import i18next, {i18n} from "i18next";
import HttpApi, {BackendOptions} from 'i18next-http-backend';
import {initReactI18next} from "react-i18next";

import {languageDetectorFactory} from "@app/App/I18Provider/LanguageDetectorFactory";
import {Config} from "@app/config";

export const LOCAL_STORAGE_LANGUAGE_KEY = 'language';
export const COOKIE_LANGUAGE_KEY = 'language';

let i18nInstance: i18n | null = null;

export const i18nFactory = (config: Config): i18n => {
    if (!i18nInstance) {
        i18nInstance = i18next.createInstance();

        const LanguageDetector = languageDetectorFactory(config);

        void i18nInstance
            .use(HttpApi)
            .use(LanguageDetector)
            .use(initReactI18next)
            .init<BackendOptions>({
                backend: {
                    loadPath: `/translations/{{lng}}.json?release=${config.release}`,
                },
                supportedLngs: config.languages,
                detection: {
                    order: ['cookie', 'localStorage', 'normalizedNavigator', 'defaultLanguage'],
                    lookupCookie: COOKIE_LANGUAGE_KEY,
                    lookupLocalStorage: LOCAL_STORAGE_LANGUAGE_KEY,
                    caches: ['cookie', 'localStorage'],
                    cookieMinutes: 40320, // 28 days cookie expiration
                },
                react: {
                    useSuspense: true,
                },
                load: 'currentOnly',
                keySeparator: '.',
                debug: false,
                fallbackLng: config.fallbackLng,
                interpolation: {
                    escapeValue: false,
                }
            });
    }

    return i18nInstance;
}
