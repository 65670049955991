import {FC, useCallback} from "react";
import {useTranslation} from "react-i18next";

import {Select} from "@app/common/components/form/Select/Select";
import {useAppConfig} from "@app/common/hooks/useAppConfig";

const LanguageLabels: {[language: string]: string} = {
    'en-GB': 'english',
    'cs-CZ': 'česky',
    'de-DE': 'deutsch',
    'it-IT': 'italiano',
    'pl-PL': 'polski',
    'ru-RU': 'русский',
    'sk-SK': 'slovensky',
    'uk-UA': 'українець',
    'vi-VN': 'Tiếng việt',
}

export type LanguageSelectProps = {
    onChange?: () => void;
}

export const LanguageSelect: FC<LanguageSelectProps> = ({onChange}) => {
    const {i18n} = useTranslation();
    const appConfig = useAppConfig();

    const label = (language: string): string => {
        if (language in LanguageLabels) {
            return LanguageLabels[language];
        }

        return language;
    }

    const changeLanguage = useCallback(async (language: string) => {
        await i18n.changeLanguage(language);

        if (onChange) {
            onChange();
        }
    }, [i18n, onChange]);

    return <Select defaultValue={i18n.language}>
        {appConfig.languages.map((language ) => {
            return <option
                key={language}
                value={language}
                onClick={() => changeLanguage(language)}>
                {label(language)}
            </option>
        })}
    </Select>
}
