import {FC} from "react";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";

import {AnonymousLayout} from "@app/common/layouts/AnonymousLayout/AnonymousLayout";
import {useTitle} from "@app/common/layouts/hooks/useTitle";
import {ForgottenPasswordPagePath} from "@app/ForgottenPassword/ForgottenPasswordPage";
import {LoginForm} from "@app/Login/LoginForm/LoginForm";
import {RegisterPagePath} from "@app/Register/RegisterPage";

export const LoginPage: FC = () => {
    const {t} = useTranslation();
    useTitle(t('Login.title'));

    return <AnonymousLayout>
        <h1>{t('Login.title')}</h1>
        <p>{t('Login.alreadyRegistered')}</p>
        <LoginForm/>
        <p>
            {t('Login.notRegistered')}{' '}
            <Link to={RegisterPagePath}>{t('Login.registerHere')}</Link>
        </p>
        <p><Link to={ForgottenPasswordPagePath}>{t('Login.forgottenPassword')}</Link></p>
    </AnonymousLayout>

}

export const LoginPagePath = '/login';
