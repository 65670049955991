import {FC} from "react";
import {useTranslation} from "react-i18next";
import {Link, UIMatch, useMatches} from "react-router-dom";

import {TitleRouteHandle} from "@app/common/model/TitleRouteHandle";

import './breadcrumbs.scss';

export const Breadcrumbs: FC = () => {
    const {t} = useTranslation();
    const routerMatches = useMatches() as UIMatch<unknown, TitleRouteHandle|undefined>[];

    return <div className="breadcrumbs">
        {routerMatches.map((match) => (match.handle ? <span className="breadcrumbs__item" key={match.id}>
            <Link
                to={match.pathname}
                key={match.id}
            >{t(match.handle.title)}
            </Link>
        </span> : null))}
    </div>
}
