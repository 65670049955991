import {FC} from "react";
import {ToastContainer as ToastifyContainer} from "react-toastify";

import './toastContainer.scss';

export type ToastContainerProps = {
    containerId?: string;
}

export const ToastContainer: FC<ToastContainerProps> = ({containerId}) => {
    return <div className="toast-container">
        <ToastifyContainer
            position="top-center"
            autoClose={false}
            containerId={containerId || 'app'}
            newestOnTop
            closeButton={false}
            rtl={false}
        />
    </div>

    /*
    <ToastifyContainer
        position="top-center"
        autoClose={5000}
        containerId={containerId || 'app'}
        newestOnTop
        closeOnClick
        closeButton={false}
        rtl={false}
    />
     */
}
