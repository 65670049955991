import {FC} from "react";

import {Logo} from "@app/common/layouts/components/Logo/Logo";
import {MainMenu} from "@app/common/layouts/SecuredLayout/components/Header/MainMenu/MainMenu";

import './header.scss';

export const Header: FC = () => {
    return <header>
        <Logo  />
        <MainMenu />
    </header>
}
