export type ErrorResponse = {
    code: number;
    message: string;
    errors: string[];
}

export const isErrorResponse = (error: unknown): error is ErrorResponse => {
    if (error !== null && typeof error === 'object') {
        return 'code' in error && 'message' in error && 'errors' in error;
    }

    return false;
}
