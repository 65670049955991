import classNames from "classnames";
import {TypeOptions} from "react-toastify";

import {FCWithChildren} from "@app/common/model/FCWithChildren";

import './alert.scss';

export type AlertProps = {
    type: TypeOptions;
    className?: string;
    close?: () => void;
}

export const Alert: FCWithChildren<AlertProps> = ({children, className, close, type}) => {

    return <div className={classNames(className, 'alert', `alert--${type}`)}>
        <div className="alert__content">
            {children}
        </div>
        {close && <a onClick={close} className="alert__close">OK</a>}
    </div>
}
