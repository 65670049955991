export type Config = {
    appName: string;
    environment: 'development'|'production';
    release?: string;
    languages: string[];
    defaultLng: string;
    fallbackLng: string;
    apiUrl: string;
    webUrl: string;
    goPayEmbedUrl: string;
    gaId: string;
    fbPixelId: string;
    includeSmartsUpp: boolean;
    includeGtm: boolean;
}

export const appConfig: Config = {
    appName: 'Czech Taxes Online',
    environment: process.env.ENVIRONMENT,
    release: process.env.RELEASE,
    languages: process.env.LANGUAGES,
    defaultLng: process.env.DEFAULT_LANGUAGE,
    fallbackLng: process.env.FALLBACK_LANGUAGE,
    apiUrl: process.env.API_URL,
    webUrl: process.env.WEB_URL,
    goPayEmbedUrl: process.env.GOPAY_EMBED_URL,
    gaId: process.env.GA_ID,
    fbPixelId: process.env.FB_PIXEL_ID,
    includeSmartsUpp: process.env.INCLUDE_SMARTSUPP === '1',
    includeGtm: process.env.INCLUDE_GTM === '1',
}
