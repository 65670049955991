import {DetailedHTMLProps, SelectHTMLAttributes} from "react";

import {FCWithChildren} from "@app/common/model/FCWithChildren";

import './select.scss';

export type SelectProps = DetailedHTMLProps<SelectHTMLAttributes<HTMLSelectElement>, HTMLSelectElement>;

export const Select: FCWithChildren<SelectProps> = ({children, ...selectProps}) => {
    return <select {...selectProps}>
        {children}
    </select>
}
