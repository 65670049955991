import {FC} from "react";
import {Link} from "react-router-dom";

import {useAppConfig} from "@app/common/hooks/useAppConfig";
import {DashboardPagePath} from "@app/Dashboard/DashboardPage";

import logo from './logo-blue.svg';
import './logo.scss'

export const Logo: FC = () => {
    const {appName} = useAppConfig();

    return <div className="logo">
        <Link to={DashboardPagePath} reloadDocument={true}>
            <img src={logo} alt={appName}/>
        </Link>
    </div>
}
